import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute'

import Home from './pages/home';
import CategoriesScreen from './pages/categories';
import CourseScreen from './pages/courses';
import Login from './pages/Login'
import EditCourse from './pages/editCourse';
import Quizzes from './pages/quizzes';
import EditQuiz from './pages/editQuiz';
import Staff from './pages/staff';
import UserRoles from './pages/userRoles';
import NotFoundPage from './pages/NotFound';
import Onboard from './pages/onboard';
import EditStaff from './pages/editStaff';
import Profile from './pages/profile';
import Students from './pages/students';
import EditStudent from './pages/editStudent';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/onboard/:invitationCode" element={<Onboard />} />
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/categories" element={<ProtectedRoute><CategoriesScreen /></ProtectedRoute>} />
          <Route path="/courses" element={<ProtectedRoute><CourseScreen /></ProtectedRoute>} />
          <Route path="/editCourse" element={<ProtectedRoute><EditCourse /></ProtectedRoute>} />
          <Route path="/quizzes" element={<ProtectedRoute><Quizzes /></ProtectedRoute>} />
          <Route path="/editQuiz" element={<ProtectedRoute><EditQuiz /></ProtectedRoute>} />
          <Route path="/staff" element={<ProtectedRoute><Staff /></ProtectedRoute>} />
          <Route path="/students" element={<ProtectedRoute><Students /></ProtectedRoute>} />
          <Route path="/editStaff/:staffID" element={<ProtectedRoute><EditStaff /></ProtectedRoute>} />
          <Route path="/editStudent/:studentId" element={<ProtectedRoute><EditStudent /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/userRoles" element={<ProtectedRoute><UserRoles /></ProtectedRoute>} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
