import React, { useState } from "react";
import fetch from 'node-fetch';
import Header from "../components/header";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { useDispatch, useSelector } from 'react-redux';
import { loginStart, loginSuccess, loginFailure } from '../redux/auth/authenticationSlice';
import { useHistory, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function Login() {

    const [dataLoading, setDataLoading] = useState(false);

    const [username, setUsername] = useState("");

    const [password, setPassword] = useState("");

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const processLogin = async () => {

        setDataLoading(true);

        const body = { username: username, password: password, requestKey: process.env.REACT_APP_SERVER_KEY };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/processLogin`, {
            method: 'post',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();

        if (data.feedback == "not_found") {
            
            setDataLoading(false);
            toast.error("User not found",{
                position:"top-center"
            })
            return;

        } else if (data.feedback == "wrong_pass") {

            setDataLoading(false);
            toast.error("Wrong password",{
                position:"top-center"
            })
            return;

        } else if (data.feedback == "success") {

            toast.success("Login succesfull",{
                position:"top-center"
            })
        
            dispatch({ type: 'LOGIN', user: { username: username, loggedIn: true, userId: data.data.userId, permissions:data.data.permissions  } });

            navigate('/');

            return;

        } else {
            setDataLoading(false);
            toast.error("An unknown error occurred, please contact admin",{
                position:"top-center"
            })
            return;
        }

        //alert("Hello")

        setDataLoading(false);

    }

    return (
        <>
            <Header />
            <>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5" style={{ padding: 20 }}>

                            <Card className="shadow-lg">
                                <Card.Body>
                                    <h1 className="text-center">Login</h1>
                                    <p className="text-center">Enter your username and password to login</p>

                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email"
                                                value={username}
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password"
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                }}
                                            />
                                        </Form.Group>

                                        <div className="text-center">
                                            <Button
                                                variant="primary"
                                                disabled={dataLoading}
                                                onClick={!dataLoading ? processLogin : null}
                                            >
                                                {dataLoading ? 'Logging in...' : 'Login'}
                                            </Button>
                                        </div>

                                    </Form>
                                </Card.Body>
                            </Card>

                        </div>
                    </div>
                </div>

            </>
        </>
    )

}

export default Login;