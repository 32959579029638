import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate, useParams } from "react-router-dom";
import { Placeholder, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Form, Tab, Tabs, Card, Table, Modal } from "react-bootstrap";

function EditStaff() {

    const userId = useSelector((e) => {
        if(e.user.user){
        return e.user.user.userId;
        }
    })

    const { staffID } = useParams();

    const navigate = useNavigate();
    const [staff, setStaff] = useState({});
    const [userStatus, setUserStatus] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [key, setKey] = useState("permissions");

    const [permissions, setPermissions] = useState([]);
    const [actionType, setActionType] = useState('addPermission');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [permissionId, setPermissionId] = useState("");
    const [adding, setAdding] = useState(false);
    const [module, setModule] = useState("");
    const [userPermissions, setUserPermissions] = useState({ create: false, read: false, update: false, delete: false });

    const modules = [
        {
            value: "CATEGORIES", label: "Categories"
        },
        {
            value: "COURSES", label: "Courses"
        },
        {
            value: "QUIZZES", label: "Quizzes"
        },
        {
            value: "ENROLLMENTS", label: "Enrollments"
        },
        {
            value: "PAYMENTS", label: "Payments"
        },
        {
            value: "STAFF", label: "Staff"
        },
        {
            value: "STUDENTS", label: "Students"
        }
    ]

    const loadStaff = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/loadStaff?userId=${staffID}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

            const response = await request.json();

            setStaff(response.data[0])

            console.log(response)

            setIsLoading(false);

        } catch (e) {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
        }
    }

    useEffect(() => {

        if (userId == null) {
            //navigate('/quizzes');
            return;
        }

        loadStaff();
        getPermissions();

    }, [])

    const updateStaff = async (e) => {

        e.preventDefault();

        if (!userStatus) {
            toast.error("Status field is compulsory", {
                position: "top-center"
            })
            return;
        }

        setIsLoading(true);

        const postData = { userId: staffID, userStatus, password: password, requestKey: process.env.REACT_APP_SERVER_KEY }

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/updateStaff`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const response = await request.json();

            console.log(response)

            if (response.feedback == "success") {
                toast.success("Profile updated successfully", {
                    position: "top-center"
                })
            } else {
                toast.error("An error occurred22", {
                    position: "top-center"
                })
            }

            setIsLoading(false);

        } catch (e) {
            toast.error("An error occurred1", {
                position: "top-center"
            })

            setIsLoading(false);
        }

    }

    const getPermissions = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/permissions?userId=${staffID}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

            const response = await request.json();

            setPermissions(response.data);

        } catch (e) {

        }

    }


    const customStyles = {
        control: (base) => ({
            ...base,
            borderColor: "#ced4da",
            ":hover": {
                borderColor: "#ced4da",
            },
            boxShadow: "none",
        }),
    };

    function handleSubmit() {

        if (actionType == 'addPermission') {
            addPermissions();
        }

    }

    const addPermissions = async () => {

        if (!module.value) {
            toast.error("Module is required!", {
                position: "top-center"
            })
            return;
        }

        setAdding(true);

        const postData = { requestKey: process.env.REACT_APP_SERVER_KEY, userId, staffID, module: module.value, permissions: userPermissions };

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/createPermissions`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const response = await request.json();

            if (response.feedback == "success") {
                getPermissions();
                toast.success("Permission added successfully", {
                    position: "top-center"
                })
                setShowModal(false)
            } else {
                toast.error(response.message, {
                    position: "top-center"
                })
            }

            setAdding(false);


        } catch (e) {
            setAdding(false);
        }

    }

    async function deletePermision() {

        if (!permissionId) {
            toast.error("Something isn't right, try again!", {
                position: "top-center"
            })
            return;
        }

        setAdding(true);

        const postData = { requestKey: process.env.REACT_APP_SERVER_KEY, permissionId, userId: staffID };

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/deletePermission`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const response = await request.json();

            if (response.feedback == "success") {
                getPermissions();
                toast.success("Permission deleted successfully", {
                    position: "top-center"
                })
                setShowDeleteModal(false)
            } else {
                toast.error(response.message, {
                    position: "top-center"
                })
            }

            setAdding(false);


        } catch (e) {
            setAdding(false);
        }

    }

    return (
        <>
            <Header />
            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isUpdating &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col lg="11">

                            <h1>{pageTitle}</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <div>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder.Button variant="primary" xs={6} />
                                    </div>
                                }
                                {!isLoading &&
                                    <>
                                        <Row>
                                            <Col lg="12" className="">
                                                <h1>Staff Details</h1>
                                                <hr />
                                                <Row>
                                                    <Col lg="7">
                                                        <Form id="myForm" onSubmit={updateStaff}>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Name
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    {staff.fullName}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Email
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    {staff.email}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Username
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    {staff.username}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Change Password
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Form.Control type="text" placeholder="Change password" value={password} onChange={(e) => {
                                                                        setPassword(e.target.value);
                                                                    }} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    User Status
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Form.Control
                                                                        as="select"
                                                                        value={userStatus}
                                                                        onChange={(e) => setUserStatus(e.target.value)}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        <option key="1" value="1">
                                                                            Active
                                                                        </option>
                                                                        <option key="0" value="0">
                                                                            Inactive
                                                                        </option>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3">
                                                                <Col sm={{ span: 10, offset: 2 }}>
                                                                    <Button type="submit">Update</Button>
                                                                </Col>
                                                            </Form.Group>

                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="8" >
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => setKey(k)}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="permissions" title="Permissions">

                                                        <Row style={{ marginBottom: "10px" }}>
                                                            <Col>
                                                                <Button className="btn-sm" variant="primary" onClick={() => {
                                                                    setActionType("addPermission")
                                                                    setShowModal(true)
                                                                }}>
                                                                    Add Permission
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Module</th>
                                                                    <th>Permissions</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {permissions.map((permission) => (
                                                                    <tr key={permission.id}>
                                                                        <td>
                                                                            {permission.module}
                                                                        </td>
                                                                        <td>
                                                                            {permission.permissions}
                                                                        </td>
                                                                        <td>
                                                                            <Button variant="danger" onClick={() => {
                                                                                setPermissionId(permission.id);
                                                                                setShowDeleteModal(true);
                                                                            }} >
                                                                                Delete
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>

                                                        {/* END DISPLAY LESSONS */}

                                                    </Tab>
                                                    <Tab eventKey="Log" title="Log">
                                                        Tab content for user log
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>

                                    </>
                                }

                            </div>
                        </Col>
                    </Row>

                </Col >

            </Row >

            {/* Modal for Creating New Permission */}
            <Modal show={showModal} onHide={() => {
                setActionType("");
                setShowModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {actionType == "addPermission" &&
                            <>Add Permission</>
                        }
                        {actionType != "addPermission" &&
                            <>Update Permission</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Module
                            </Form.Label>
                            <Col sm={9}>
                                <Select
                                    value={module}
                                    onChange={(selected) => { setModule(selected) }}
                                    options={modules}
                                    isSearchable={true}
                                    placeholder="Search for a module..."
                                    styles={customStyles}
                                />
                            </Col>
                        </Form.Group>

                        <Row>
                            <Col>
                                <h5>Permissions</h5>
                            </Col>
                        </Row>

                        <Row>

                            <Col>
                                <Form.Group controlId={`isAnswer`}>
                                    <Form.Label>Create</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        name="create"
                                        checked={userPermissions.create}
                                        onChange={(e) => {

                                            setUserPermissions(previousPemissions => ({
                                                ...previousPemissions,
                                                create: e.target.checked
                                            }))
                                            console.log(userPermissions);
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId={`isAnswer`}>
                                    <Form.Label>Read</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        name="read"
                                        checked={userPermissions.read}
                                        onChange={(e) => {
                                            setUserPermissions(previousPemissions => ({
                                                ...previousPemissions,
                                                read: e.target.checked
                                            }))
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId={`isAnswer`}>
                                    <Form.Label>Update</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        name="update"
                                        checked={userPermissions.update}
                                        onChange={(e) => {
                                            setUserPermissions(previousPemissions => ({
                                                ...previousPemissions,
                                                update: e.target.checked
                                            }))
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId={`isAnswer`}>
                                    <Form.Label>Delete</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        name="delete"
                                        checked={userPermissions.delete}
                                        onChange={(e) => {
                                            setUserPermissions(previousPemissions => ({
                                                ...previousPemissions,
                                                delete: e.target.checked
                                            }))
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        {adding &&
                            <center>
                                <div>
                                    <span className="loader"></span>
                                </div>
                            </center>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        {actionType == "addPermission" ? <>Add Permission</> : <>Update Permission</>}
                    </Button>

                    <Button variant="secondary" onClick={() => {
                        setActionType("");
                        setShowModal(false);
                    }}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            {/* Modal for Deleting Permission */}
            <Modal show={showDeleteModal} onHide={() => {
                setPermissionId("");
                setShowDeleteModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Permission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete????
                    </p>

                    {adding &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={deletePermision}>
                        Yes
                    </Button>
                    <Button variant="danger" onClick={() => {
                        setPermissionId("");
                        setShowDeleteModal(false);
                    }}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )

}

export default EditStaff;