import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate, useParams } from "react-router-dom";
import { Placeholder, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Form, Tab, Tabs, Card, Table, Modal } from "react-bootstrap";

function EditStudent() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })

    const { studentId } = useParams();

    const navigate = useNavigate();
    const [student, setStudent] = useState({});
    const [userStatus, setUserStatus] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [key, setKey] = useState("enrollments");

    const loadStudent = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/loadStudent?userId=${studentId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

            const response = await request.json();

            setStudent(response.data[0])

            console.log(response)

            setIsLoading(false);

        } catch (e) {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
        }
    }

    useEffect(() => {

        if (userId == null) {
            //navigate('/quizzes');
            return;
        }

        loadStudent();

    }, [])

    const updateStudent = async (e) => {

        e.preventDefault();

        if (!userStatus) {
            toast.error("Status field is compulsory", {
                position: "top-center"
            })
            return;
        }

        setIsLoading(true);

        const postData = { userId: studentId, userStatus, password: password, requestKey: process.env.REACT_APP_SERVER_KEY }

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/updateStudent`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const response = await request.json();

            console.log(response)

            if (response.feedback == "success") {
                toast.success("Profile updated successfully", {
                    position: "top-center"
                })
            } else {
                toast.error("An error occurred22", {
                    position: "top-center"
                })
            }

            setIsLoading(false);

        } catch (e) {
            toast.error("An error occurred1", {
                position: "top-center"
            })

            setIsLoading(false);
        }

    }

    const customStyles = {
        control: (base) => ({
            ...base,
            borderColor: "#ced4da",
            ":hover": {
                borderColor: "#ced4da",
            },
            boxShadow: "none",
        }),
    }

    return (
        <>
            <Header />
            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isUpdating &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col lg="11">

                            <h1>{pageTitle}</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <div>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder.Button variant="primary" xs={6} />
                                    </div>
                                }
                                {!isLoading &&
                                    <>
                                        <Row>
                                            <Col lg="12" className="">
                                                <h1>Student Details</h1>
                                                <hr />
                                                <Row>
                                                    <Col lg="7">
                                                        <Form id="myForm" onSubmit={updateStudent}>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Name
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    {student.fullName}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Email
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    {student.email}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Username
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    {student.username}
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    Change Password
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Form.Control type="text" placeholder="Change password" value={password} onChange={(e) => {
                                                                        setPassword(e.target.value);
                                                                    }} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={3}>
                                                                    User Status
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Form.Control
                                                                        as="select"
                                                                        value={userStatus}
                                                                        onChange={(e) => setUserStatus(e.target.value)}
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        <option key="1" value="1">
                                                                            Active
                                                                        </option>
                                                                        <option key="0" value="0">
                                                                            Inactive
                                                                        </option>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-3">
                                                                <Col sm={{ span: 10, offset: 2 }}>
                                                                    <Button type="submit">Update</Button>
                                                                </Col>
                                                            </Form.Group>

                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="8" >
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => setKey(k)}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="enrollments" title="Enrollments">
                                                        Course Enrollments
                                                    </Tab>
                                                    <Tab eventKey="subscriptions" title="Subscription History">
                                                        Subscriptions
                                                    </Tab>
                                                    <Tab eventKey="payments" title="Payments History">
                                                       Payments
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>

                                    </>
                                }

                            </div>
                        </Col>
                    </Row>

                </Col >

            </Row >

        </>
    )

}

export default EditStudent;