import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate } from "react-router-dom";
import { Placeholder, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Form, Tab, Tabs, Card, Table, Modal } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditQuiz() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })

    const urlParams = new URLSearchParams(window.location.search);
    const quizId = urlParams.get('quizId');

    const navigate = useNavigate();
    const [key, setKey] = useState('Questions');
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [quizName, setQuizName] = useState("");
    const [category, setCategory] = useState("");
    const [course, setCourse] = useState({});
    const [courses, setCourses] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categories, setCategories] = useState([]);

    const [actionType, setActionType] = useState("addQuestion");
    const [question, setQuestion] = useState("");
    const [questions, setQuestions] = useState([]);
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
    const [adding, setAdding] = useState(false);

    const [questionId, setQuestionId] = useState("");

    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const [options, setOptions] = useState([{ option: '', isAnswer: false }, { option: '', isAnswer: false }, { option: '', isAnswer: false }, { option: '', isAnswer: false }]);

    const handleChange = (content) => {

    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    const getQuestions = async () => {
        const request1 = await fetch(`${process.env.REACT_APP_API_URL}/questions?quizId=${quizId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

        const response1 = await request1.json();

        setQuestions(response1)
    }

    const loadQuiz = async () => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/loadQuiz?quizId=${quizId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

        const response = await request.json();

        if (Object.keys(response).length > 0) {

            if (response.feedback == "error") {
                toast.error("An error occurred", {
                    position: "top-center"
                })
                navigate('/quizzes');
                return;
            }

            if (response.data.length > 0) {

                setPageTitle(`Viewing ${response.data[0].quizName}`);
                setQuizName(response.data[0].quizName);
                setCategory({ value: response.data[0].categoryId, label: response.data[0].categoryName });
                setCourse({value:response.data[0].courseId, label: response.data[0].courseName})
                const request1 = await fetch(`${process.env.REACT_APP_API_URL}/questions?quizId=${quizId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

                const response1 = await request1.json();

                setQuestions(response1)
            }
            setIsLoading(false);

        } else {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
        }
    }

    useEffect(() => {

        if (quizId == null) {
            navigate('/quizzes');
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/categories?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => {
                const formattedData = data.map(category => ({
                    value: category.id,
                    label: category.name
                }));
                setCategories(formattedData)
            });

        loadQuiz();

    }, [])

    const updateQuiz = async (e) => {

        e.preventDefault();

        setIsLoading(true);

        const postData = { quizId: quizId, categoryId: category.value, courseId: course.value, quizName: quizName, requestKey: process.env.REACT_APP_SERVER_KEY }

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/updateQuiz`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (Object.keys(request).length > 0) {

                const response = await request.json();

            }

        } catch (e) {
            console.log(e);
        }

        setIsLoading(false);

    }

    const customStyles = {
        control: (base) => ({
            ...base,
            borderColor: "#ced4da",
            ":hover": {
                borderColor: "#ced4da",
            },
            boxShadow: "none",
        }),
    };

    const addOption = () => {
        setOptions([...options, { option: '', isAnswer: false }]);
    };

    const handleOptionChange = (e, index) => {
        const { name, value, type, checked } = e.target;
        const updatedOptions = [...options];
        updatedOptions[index][name] = type === 'checkbox' ? checked : value;
        setOptions(updatedOptions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!question) {
            toast.error("Add a question to continue!", {
                position: "top-center"
            })
            return;
        }

        if (!options[0].option && !options[1].option) {
            toast.error("You need to add two options atleast", {
                position: "top-center"
            })
            return;
        }

        let theAnswer = [];

        for (var i = 0; i < options.length; i++) {

            theAnswer.push(options[i].isAnswer)

        }

        if (!theAnswer.includes(true)) {
            toast.error("No answer selected!", {
                position: "top-center"
            })
            return;
        }

        setAdding(true);

        // Extract options and answers
        const optionColumns = ['option1', 'option2', 'option3', 'option4'];
        const organizedOptions = {};
        const answers = [];

        options.forEach((opt, index) => {
            organizedOptions[optionColumns[index]] = opt.option;
            if (opt.isAnswer) {
                answers.push(optionColumns[index]);
            }
        });

        const formData = {
            userId,
            quizId,
            question,
            ...organizedOptions,
            answer: answers.join(', '),
            requestKey: process.env.REACT_APP_SERVER_KEY
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseData = await response.json();

            if (Object.keys(responseData).length > 0) {

                clearForm();

                getQuestions();

                console.log('Submission successful:', responseData);

                toast.success("Question Added Successfully!", {
                    position: "top-center"
                })

                setShowAddQuestionModal(false);

            } else {
                toast.error("An error occurred!", {
                    position: "top-center"
                })
                return;
            }
        } catch (error) {
            toast.error("An error occurred!", {
                position: "top-center"
            })
            return;
        }
    };

    function ellipsisSentence(sentence, maxLength = 30) {
        if (sentence.length <= maxLength) return sentence;
        return sentence.substring(0, maxLength - 3) + '...';
    }

    const loadQuestion = async (questionId) => {

        setIsUpdating(true)

        const request = await fetch(`${process.env.REACT_APP_API_URL}/loadQuestion?questionId=${questionId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

        const response = await request.json();

        if (Object.keys(response).length > 0) {

            if (response.feedback == "error") {
                toast.error("An error occurred", {
                    position: "top-center"
                })

            }

            if (response.data.length > 0) {

                setQuestionId(questionId);

                const questionData = response.data[0];

                let answer = response.data[0].answer;

                setQuestion(questionData.question);

                answer = answer.split(",");

                let optionValues = [];

                let options = ['option1', 'option2', 'option3', 'option4'];

                for (var a = 0; a < options.length; a++) {

                    for (var b = 0; b < answer.length; b++) {
                        let currentAnswer = answer[b];
                        currentAnswer = currentAnswer.trim();

                        if (currentAnswer === options[a]) {
                            optionValues[options[a]] = true;
                        }
                    }
                }

                setOptions([
                    { option: questionData.option1, isAnswer: optionValues['option1'] },
                    { option: questionData.option2, isAnswer: optionValues['option2'] },
                    { option: questionData.option3, isAnswer: optionValues['option3'] },
                    { option: questionData.option4, isAnswer: optionValues['option4'] }
                ]);

                setIsUpdating(false);

                setShowAddQuestionModal(true);
            }


        } else {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsUpdating(false);
        }
    }

    const updateQuestion = async () => {

        if (!question) {
            toast.error("Add a question to continue!", {
                position: "top-center"
            })
            return;
        }

        if (!options[0].option && !options[1].option) {
            toast.error("You need to add two options atleast", {
                position: "top-center"
            })
            return;
        }

        let theAnswer = [];

        for (var i = 0; i < options.length; i++) {

            theAnswer.push(options[i].isAnswer)

        }

        if (!theAnswer.includes(true)) {
            toast.error("No answer selected!", {
                position: "top-center"
            })
            return;
        }

        setAdding(true);

        // Extract options and answers
        const optionColumns = ['option1', 'option2', 'option3', 'option4'];
        const organizedOptions = {};
        const answers = [];

        options.forEach((opt, index) => {
            organizedOptions[optionColumns[index]] = opt.option;
            if (opt.isAnswer) {
                answers.push(optionColumns[index]);
            }
        });

        const formData = {
            userId,
            questionId,
            question,
            ...organizedOptions,
            answer: answers.join(', '),
            requestKey: process.env.REACT_APP_SERVER_KEY
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/updateQuestion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseData = await response.json();

            if (Object.keys(responseData).length > 0) {

                getQuestions();

                toast.success("Question Updated Successfully!", {
                    position: "top-center"
                })

                setShowAddQuestionModal(false);

                clearForm();

                setAdding(false);

            } else {
                setQuestionId("");
                setAdding(false);
                toast.error("An error occurred!", {
                    position: "top-center"
                })
                return;
            }
        } catch (error) {
            setQuestionId("");
            setAdding(false);
            toast.error("An error occurred!", {
                position: "top-center"
            })
            return;
        }


    }

    const clearForm = () => {
        setQuestion("");
        setOptions([
            { option: "", isAnswer: false },
            { option: "", isAnswer: false },
            { option: "", isAnswer: false },
            { option: "", isAnswer: false }
        ]);
    }

    const deleteQuestion = async () => {

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/deleteQuestion?questionId=${questionId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`)

            const response = await request.json();

            setAdding(true);

            if (response.feedback == "success") {

                getQuestions();
                toast.success("Question deleted", {
                    position: "top-center"
                })
                setShowDeleteQuestionModal(false);


            } else {

                toast.error("An error occurred", {
                    position: "top-center"
                })


            }
            setAdding(false);
            setQuestionId("");

        } catch (e) {

            setQuestionId("");
            setAdding(false);

            toast.error("An error occurred", {
                position: "top-center"
            })
            return;
        }

    }

    const getCourses = async (categoryId) => {

        setIsUpdating(true);
        //setCourse(null)

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/courses/${categoryId}?requestKey=${process.env.REACT_APP_SERVER_KEY}`);
            const response = await request.json();

            const formattedResponse = response.data.map((row) => ({
                value: row.id,
                label: row.name
            }))
            setCourses(formattedResponse)
            setIsUpdating(false);

        } catch (e) {
            setIsUpdating(false);
        }

    }

    useEffect(()=>{
        if(category.value){
            getCourses(category.value);
        }
    },[category.value])

    return (
        <>
            <Header />
            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isUpdating &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }


                    <Row>
                        <Col lg="11">

                            <h1>{pageTitle}</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <div>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder.Button variant="primary" xs={6} />
                                    </div>
                                }
                                {!isLoading &&
                                    <>
                                        <Row>
                                            <Col lg="8" className="mx-auto">
                                                <Form onSubmit={updateQuiz} id="myForm">

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Category
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Select
                                                                value={category}
                                                                onChange={(selected) => { console.log(selected); setCategory(selected) }}
                                                                options={categories}
                                                                isSearchable={true}
                                                                placeholder="Search for a category..."
                                                                styles={customStyles}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Course
                                                        </Form.Label>

                                                        <Col sm={9}>
                                                            <Select
                                                                value={course}
                                                                onChange={(selected) => { setCourse(selected) }}
                                                                options={courses}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                placeholder="Search for a course"
                                                                styles={customStyles}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Quiz Name
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Form.Control type="text" placeholder="Course Name" value={quizName} onChange={(e) => {
                                                                setQuizName(e.target.value)
                                                            }} />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3">
                                                        <Col sm={{ span: 10, offset: 2 }}>
                                                            <Button type="submit">Update</Button>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="8" className="mx-auto">
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => setKey(k)}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="Questions" title="Questions">
                                                        {/* START DISPLAY LESSONS */}

                                                        <Row style={{ marginBottom: "10px" }}>
                                                            <Col>
                                                                <Button className="btn-sm" variant="primary" onClick={() => {
                                                                    setActionType("addQuestion")
                                                                    setShowAddQuestionModal(true)
                                                                }}>
                                                                    Add New Question
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Question</th>
                                                                    <th>Status</th>
                                                                    <th>Update</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {questions.map((question) => (
                                                                    <tr key={question.id}>
                                                                        <td>{ellipsisSentence(question.question)}</td>
                                                                        <td>
                                                                            {question.status == 1 &&
                                                                                <>Active</>
                                                                            }
                                                                            {question.status != 1 &&
                                                                                <>Inactive</>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <Button variant="info" onClick={() => {
                                                                                setActionType("updateQuestion");
                                                                                loadQuestion(question.id);
                                                                            }} >
                                                                                Update
                                                                            </Button>
                                                                        </td>
                                                                        <td>
                                                                            <Button variant="danger" onClick={() => {
                                                                                setQuestionId(question.id);
                                                                                setShowDeleteQuestionModal(true);
                                                                            }} >
                                                                                Delete
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>

                                                        {/* END DISPLAY LESSONS */}

                                                    </Tab>
                                                    <Tab eventKey="Reports" title="Reports">
                                                        Tab content for Quiz Reports
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </>
                                }

                            </div>
                        </Col>
                    </Row>

                </Col>

            </Row>

            {/* Modal for Creating New Question */}
            <Modal show={showAddQuestionModal} onHide={() => {
                setQuestion("");
                clearForm();
                setShowAddQuestionModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {actionType == "addQuestion" &&
                            <>Add New Question</>
                        }
                        {actionType != "addQuestion" &&
                            <>Update Question</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Question</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                            />
                        </Form.Group>
                        {options.map((opt, index) => (
                            <Row key={index}>
                                <Col>
                                    <Form.Group controlId={`option${index + 1}`}>
                                        <Form.Label>Option {index + 1}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="option"
                                            value={opt.option}
                                            onChange={(e) => {
                                                handleOptionChange(e, index)
                                            }}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId={`isAnswer${index + 1}`}>
                                        <Form.Label>Is Answer</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            name="isAnswer"
                                            checked={opt.isAnswer}
                                            onChange={(e) => handleOptionChange(e, index)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        ))}

                    </Form>

                    {adding &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setQuestionId("");
                        clearForm();
                        setShowAddQuestionModal(false);
                    }}>
                        Close
                    </Button>
                    {!adding &&
                        <></>
                    }
                    {actionType == "addQuestion" &&
                        <Button variant="primary" onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    }
                    {actionType == "updateQuestion" &&
                        <Button variant="primary" onClick={updateQuestion}>
                            Save Changes
                        </Button>
                    }

                </Modal.Footer>
            </Modal>

            {/* Modal for Deleting Question */}
            <Modal show={showDeleteQuestionModal} onHide={() => {
                setQuestionId("");
                setShowDeleteQuestionModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete????
                    </p>

                    {adding &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }

                    {adding &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={deleteQuestion}>
                        Yes
                    </Button>
                    <Button variant="danger" onClick={() => {
                        setQuestionId("");
                        setShowDeleteQuestionModal(false);
                    }}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default EditQuiz;