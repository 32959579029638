import React, { useEffect, useState, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import { Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function Menu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const buttonStyle = { width: "80%", marginBottom: 5, marginRight: 5 };

    const user = useSelector(state => state.user.user);
    const permissions = useSelector(state => state.user.user.permissions);

    const [categoryAccess, setCategoryAccess] = useState(false);
    const [coursesAccess, setCoursesAccess] = useState(false);
    const [quizzesAccess, setQuizzesAccess] = useState(false);
    const [enrollmentsAccess, setEnrollmentAccess] = useState(false);
    const [subscriptionPlansAccess, setSubscriptionPlansAccess] = useState(false);
    const [subscriptionsAccess, setSubscriptionsAccess] = useState(false);
    const [paymentsAccess, setPaymentsAccess] = useState(false);
    const [usersAccess, setUsersAccess] = useState(false);

    /*const permissions = usePermissions(user?.userId);

    const isAllowedAccess = useMemo(() => {
        const categoryPermission = permissions.find(p => p.module === "CATEGORIES");
        return {
            'CATEGORIES': !!categoryPermission && JSON.parse(categoryPermission.permissions).read
        };
    }, [permissions]);

    useEffect(() => {
        if (!user) navigate("/Login");
    }, [user, navigate]);*/

    const [buttonWidth] = useState("80%");

    const button = { width: buttonWidth, marginBottom: 5, marginRight: 5 }

    useEffect(() => {
        for (var a = 0; a <= permissions.length; a++) {
            if (permissions[a]) {
                if (permissions[a].module == "CATEGORIES") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setCategoryAccess(userPermissions.read);
                }

                if (permissions[a].module == "COURSES") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setCoursesAccess(userPermissions.read);
                }

                if (permissions[a].module == "QUIZZES") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setQuizzesAccess(userPermissions.read);
                }
                if (permissions[a].module == "PAYMENTS") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setPaymentsAccess(userPermissions.read);
                }
                if (permissions[a].module == "STAFF") {
                    const userPermissions = JSON.parse(permissions[a].permissions)
                    setUsersAccess(userPermissions.read);
                }
            }
        }
    }, [])

    return (
        <Col lg="2" className='d-none d-lg-block left_home'>

            <div style={{ alignContent: "center", alignItems: "center", textAlign: "center" }}>

                <Button className='shadow-lg' variant="light" style={button} onClick={() => { navigate("/") }}>Home</Button>{' '}

                {categoryAccess &&
                    <Button variant="light" style={button} onClick={() => { navigate("/categories") }}>Categories</Button>
                }
                {coursesAccess &&
                    <Button variant="light" style={button} onClick={() => { navigate("/courses") }}>Courses</Button>
                }
                {quizzesAccess &&
                    <Button variant="light" style={button} onClick={() => { navigate("/quizzes") }}>Quizzes</Button>
                }
                {paymentsAccess &&
                    <Button variant="light" style={button}>Enrollments</Button>
                }
                {paymentsAccess &&
                    <Button variant="light" style={button}>Subscription Plans</Button>
                }
                {paymentsAccess &&
                    <Button variant="light" style={button}>Subscriptions</Button>
                }
                {paymentsAccess &&
                    <Button variant="light" style={button}>Payments</Button>
                }
                {usersAccess &&
                    <Dropdown>
                        <Dropdown.Toggle variant="light" style={button}>
                            Users
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { navigate("/staff") }}>Staff</Dropdown.Item>
                            <Dropdown.Item onClick={() => { navigate("/students") }}>Students</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }

                <Button variant="light" style={button} onClick={() => { navigate("/profile") }}>My Profile</Button>{' '}

                <Button variant="light" style={button} onClick={() => { dispatch({ type: 'LOGOUT' }); navigate("/Login") }}>Logout</Button>{' '}

            </div>

        </Col>
    )

}

export default Menu;