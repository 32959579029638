// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Students() {

  const userId = useSelector((e) => {
    return e.user.user.userId;
  })
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [allStudents, setAllStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [staffEmail, setStaffEmail] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [adding, setAdding] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  useEffect(() => {

    getAllStudents()

  }, []);

  const getAllStudents = () => {
    setIsLoading(true);

    let queryUrl = `${process.env.REACT_APP_API_URL}/students?requestKey=${process.env.REACT_APP_SERVER_KEY}`;

    if (selectedCategory != "" || searchKeyword != "") {

      queryUrl = `${process.env.REACT_APP_API_URL}/students?requestKey=${process.env.REACT_APP_SERVER_KEY}&status=${selectedCategory}&keyWord=${searchKeyword}`;

    } else if (selectedCategory == "") {

      queryUrl = `${process.env.REACT_APP_API_URL}/students?requestKey=${process.env.REACT_APP_SERVER_KEY}`;

    }

    fetch(queryUrl)
      .then((res) => res.json())
      .then((data) => {
        setAllStudents(data.data)
        setIsLoading(false)
      });
  }

  const deleteStudent = async () => {

    if (!selectedID) {
      toast.error("Something isn't right, try again!", {
        position: "top-center"
      })
      return;
    }

    setAdding(true);

    const postData = { requestKey: process.env.REACT_APP_SERVER_KEY, userId: selectedID };

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/deleteStudent`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      const response = await request.json();

      if (response.feedback == "success") {
        getAllStudents();
        setSelectedID("");
        toast.success("Student deleted successfully", {
          position: "top-center"
        })
        setShowDeleteModal(false)
      } else {
        toast.error(response.message, {
          position: "top-center"
        })
      }

      setAdding(false);


    } catch (e) {
      setAdding(false);
    }
    
  }

  useEffect(() => {

    if (selectedCategory != "" || searchKeyword != "") {
      getAllStudents();
    } else if (selectedCategory == "") {
      getAllStudents();
    }

  }, [selectedCategory, searchKeyword])

  return (
    <>
      <Header />

      <Row className='home_outer'>

        <Menu />

        <Col className='right_home'>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }

          <Row>
            <Col>
              <h1>Students</h1>
            </Col>
          </Row>

          <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col>

                <Form.Group>
                  <Form.Label>Filter By Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Form.Control>
                </Form.Group>

              </Col>

              <Col>

                <Form.Group>
                  <Form.Label>Filter By Username, Email, Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col>

              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <>
                  {/* Course Table */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allStudents.map((student) => (
                        <tr key={student.id}>
                          <td>{student.fullName}</td>
                          <td>{student.email}</td>
                          <td>{student.status == 1 ? <>Active</> : <>Inactive</>}</td>
                          <td>
                            <Button variant="info" onClick={() => navigate(`/editStudent/${student.id}`)}>
                              View
                            </Button>
                          </td>
                          <td>
                            <Button variant="danger" onClick={() => {
                              setSelectedID(student.id);
                              setShowDeleteModal(true);
                            }}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                </>

              </Col>
            </Row>

          </div>

        </Col>

      </Row>

      {/* Modal for Deleting Student */}
      <Modal show={showDeleteModal} onHide={() => {
        setSelectedID("");
        setShowDeleteModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a permanent and irreversible action!
          </p>
          <p>
            Click yes if you are sure!!!
          </p>

          {adding &&
            <center>
              <div>
                <span className="loader"></span>
              </div>
            </center>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={deleteStudent}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => {
            setSelectedID("");
            setShowDeleteModal(false);
          }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Students;
