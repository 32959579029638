// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function UserRoles() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [quizzes, setQuizzes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [newQuiz, setNewQuiz] = useState({ name: '', category_id: '' });
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [courseOrderingModal, setCourseOrderingModal] = useState(false);
    const [showReOrderButton, setShowReOrderButton] = useState(false);

    // Load courses and categories from the API
    useEffect(() => {

        getQuizzes()

        fetch(`${process.env.REACT_APP_API_URL}/categories?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => setCategories(data));

    }, []);

    const handleCreateQuiz = () => {

        if (!newQuiz.name || !newQuiz.category_id) {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return;
        }
        // Prepare the new course data
        const quizData = {
            name: newQuiz.name,
            category_id: newQuiz.category_id,
            requestKey: process.env.REACT_APP_SERVER_KEY
        };

        // Send a POST request to the server to create a new course
        fetch(`${process.env.REACT_APP_API_URL}/quizzes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(quizData),
        })
            .then((response) => {
                console.log(response)
                return response.json();  // Parse the response body as JSON
            })
            .then((data) => {

                console.log(data);

                if (data.success) {

                    setNewQuiz({ name: '', category_id: '' });

                    getQuizzes();

                    navigate('/editQuiz?quizId=' + data.insertId)

                }

            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    const getQuizzes = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/quizzes?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => {
                //console.log(courses)
                setQuizzes(data)
                setIsLoading(false)
            });
    }

    const handleUpdateQuiz = (quizId) => {
        // TODO: Implement update course API call
        navigate(`/editQuiz?quizId=${quizId}`)
    };

    const handleDeleteCourse = (courseId) => {
        // TODO: Implement delete course API call
    };

    const getQuizzesByCategory = async () => {

        setIsLoading(true);

        const request = await fetch(`${process.env.REACT_APP_API_URL}/quizzes?categoryId=${selectedCategory}&keyWord=${searchKeyword}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

        const response = await request.json();

        setQuizzes(response);

        setIsLoading(false);

    }

    useEffect(() => {

        if (selectedCategory != "" || searchKeyword != "") {

            getQuizzesByCategory();

        } else if (selectedCategory == "") {

            getQuizzes();

        }

    }, [selectedCategory, searchKeyword])

    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isLoading &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col>
                            <h1>Quizzes</h1>
                        </Col>
                    </Row>

                    <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                        <Row>
                            <Col>
                                <Button variant="primary" onClick={() => setShowModal(true)}>
                                    Add User Role
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <>
                                    {/* Course Table */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Status</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quizzes.map((quiz) => (
                                                <tr key={quiz.id}>
                                                    <td>{quiz.quizName}</td>
                                                    <td>{quiz.categoryName}</td>
                                                    <td>
                                                        {quiz.status == 1 &&
                                                            <>Active</>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Button variant="info" onClick={() => handleUpdateQuiz(quiz.id)}>
                                                            View
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => handleDeleteCourse(quiz.id)}>
                                                            Delete
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>

                            </Col>
                        </Row>

                        {/* Modal for Creating New Course */}
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Quiz</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Quiz Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newQuiz.name}
                                            onChange={(e) => setNewQuiz({ ...newQuiz, name: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={newQuiz.category_id}
                                            onChange={(e) => setNewQuiz({ ...newQuiz, category_id: e.target.value })}
                                        >
                                            <option value="">
                                                Select course category
                                            </option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleCreateQuiz}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>

                </Col>

            </Row>

        </>
    );
}

export default UserRoles;
