import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate } from "react-router-dom";
import { Placeholder, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Form, Tab, Tabs, Card, Table, Modal } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditCourse() {

    const username = useSelector((e) => {
        return e.user.user.userId;
    })

    const urlParams = new URLSearchParams(window.location.search);
    const course = urlParams.get('course');

    const navigate = useNavigate();
    const [key, setKey] = useState('Videos');
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [thumbnailPhoto, setThumbnailPhoto] = useState("");
    const [category, setCategory] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categories, setCategories] = useState([]);
    const [introVideo, setIntroVideo] = useState("");
    const [tutor, setTutor] = useState("");
    const [quiz, setQuiz] = useState({ value: "", label: "" });
    const [quizzes, setQuizzes] = useState("");
    const [lessonQuiz, setLessonQuiz] = useState({value:"", label:""});
    const [courseStatus, setCourseStatus] = useState("");
    const [staffList, setStaffList] = useState("");

    const [actionType, setActionType] = useState("");
    const [lessons, setLessons] = useState([]);
    const [showAddLessonModal, setShowAddLessonModal] = useState(false);
    const [title, setTitle] = useState("");
    const [lessonDescription, setLessonDescription] = useState("");
    const [lessonThumbnailPhoto, setLessonThumbnailPhoto] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [videoLength, setVideoLength] = useState("");
    const [lessonStatus, setLessonStatus] = useState("");
    const [fileKey, setFileKey] = useState(Date.now());
    const [adding, setAdding] = useState(false);
    const [courses, setCourses] = useState([]);

    const [selectedID, setSelectedID] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleChange = (content) => {
        setCourseDescription(content);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    const loadCourse = async () => {

        const request = await fetch(`${process.env.REACT_APP_API_URL}/loadCourse?course=${course}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

        const response = await request.json();

        if (Object.keys(response).length > 0) {

            if (response.feedback == "error") {
                toast.error("An error occurred", {
                    position: "top-center"
                })
                navigate('/courses');
                return;
            }

            if (response.data.length > 0) {
                setPageTitle(`Viewing ${response.data[0].courseName}`);
                setCourseName(response.data[0].courseName);
                setCategory({ value: response.data[0].categoryId, label: response.data[0].categoryName });
                setCategoryName(response.data[0].categoryName);
                setCourseDescription(response.data[0].description);
                setThumbnailPhoto(response.data[0].thumbnailPhoto);
                setIntroVideo(response.data[0].introVideo);
                setTutor({ value: response.data[0].userId, label: response.data[0].fullName })
                setQuiz({ value: response.data[0].quizId, label: response.data[0].quizName })
                setCourseStatus(response.data[0].status);
                getLessons();
            }
            setIsLoading(false);

        } else {
            toast.error("An error occurred", {
                position: "top-center"
            })
            setIsLoading(false);
        }
    }

    const getLessons = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/lessons?courseId=${course}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

        const response = await request.json();

        setLessons(response)
    }

    useEffect(() => {

        if (course == null) {
            navigate('/courses');
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/categories?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => {
                const formattedData = data.map(category => ({
                    value: category.id,
                    label: category.name
                }));
                setCategories(formattedData)
            });

        fetch(`${process.env.REACT_APP_API_URL}/quizzes/${course}?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => {
                const formattedData = data.map(quiz => ({
                    value: quiz.id,
                    label: quiz.quizName
                }));
                setQuizzes(formattedData)
                console.log(quizzes);
            });

        fetch(`${process.env.REACT_APP_API_URL}/getStaffList?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => {
                const formattedData = data.data.map(staff => ({
                    value: staff.id,
                    label: staff.fullName
                }));
                setStaffList(formattedData)
            });

        loadCourse();

    }, [])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setThumbnailPhoto(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleLessonThumbnail = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setLessonThumbnailPhoto(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const updateCourse = async (e) => {

        e.preventDefault();

        setIsLoading(true);

        const postData = { courseId: course, categoryId: category.value, courseName: courseName, courseDescription: courseDescription, thumbnailPhoto: thumbnailPhoto, introVideo: introVideo, tutor: tutor.value, quizId: lessonQuiz.value, status: courseStatus, requestKey: `${process.env.REACT_APP_SERVER_KEY}` }

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/updateCourse`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (Object.keys(request).length > 0) {

                const response = await request.json();

            }

        } catch (e) {
            console.log(e);
        }

        setIsLoading(false);

    }

    const handleSubmit = () => {

        if (actionType == "addLesson") {
            handleCreateLesson();
        } else {
            updateLesson();
        }

    }

    const clearForm = () => {
        setTitle("");
        setLessonDescription("");
        setLessonThumbnailPhoto("");
        setFileKey(Date.now());
        setVideoLink("");
        setVideoLength("");
        setLessonQuiz({value:"",label:""})
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            borderColor: "#ced4da",
            ":hover": {
                borderColor: "#ced4da",
            },
            boxShadow: "none",
        }),
    };

    const handleCreateLesson = () => {

        if (!title) {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return;
        }
        setAdding(true);
        // Prepare the new course data
        const lessonData = {
            username: username,
            title: title,
            courseId: course,
            description: lessonDescription,
            thumbnailPhoto: lessonThumbnailPhoto,
            videoLink: videoLink,
            length: videoLength,
            quizId: lessonQuiz.value,
            requestKey: process.env.REACT_APP_SERVER_KEY
        };

        // Send a POST request to the server to create a new course
        fetch(`${process.env.REACT_APP_API_URL}/lessons`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(lessonData),
        })
            .then((response) => {
                //console.log(response)
                return response.json();  // Parse the response body as JSON
            })
            .then(async (data) => {

                if (data.success) {

                    getLessons();

                    clearForm()

                    toast.success("Lesson created!", {
                        position: "top-center"
                    })

                    setShowAddLessonModal(false);

                }

                setAdding(false);

            })
            .catch((error) => {

                toast.error("An error occurred!", {
                    position: "top-center"
                })
                setAdding(false);
            });
    };

    const updateLesson = () => {

        if (!title) {
            toast.error("All fields are required", {
                position: "top-center"
            })
            return;
        }
        setAdding(true);
        // Prepare the new course data
        const lessonData = {
            username: username,
            lessonId: selectedID,
            title: title,
            courseId: course,
            description: lessonDescription,
            thumbnailPhoto: lessonThumbnailPhoto,
            videoLink: videoLink,
            length: videoLength,
            status: lessonStatus,
            quizId: lessonQuiz.value,
            requestKey: process.env.REACT_APP_SERVER_KEY
        };

        // Send a POST request to the server to update
        fetch(`${process.env.REACT_APP_API_URL}/updateLesson`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(lessonData),
        })
            .then((response) => {
                //console.log(response)
                return response.json();  // Parse the response body as JSON
            })
            .then(async (data) => {
                if (data.success) {

                    clearForm()
                    toast.success("Lesson updated!", {
                        position: "top-center"
                    })
                    setShowAddLessonModal(false);
                    getLessons();

                }

                setAdding(false);

            })
            .catch((error) => {

                toast.error("An error occurred!", {
                    position: "top-center"
                })
                setAdding(false);
            });
    };

    const deleteLesson = async () => {

        if (!selectedID) {
            toast.error("Something isn't right, try again!", {
                position: "top-center"
            })
            return;
        }

        setAdding(true);

        const postData = { requestKey: process.env.REACT_APP_SERVER_KEY, id: selectedID };

        try {

            const request = await fetch(`${process.env.REACT_APP_API_URL}/deleteLesson`, {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const response = await request.json();

            if (response.feedback == "success") {
                getLessons();
                setSelectedID("");
                toast.success("Lesson deleted successfully", {
                    position: "top-center"
                })
                setShowDeleteModal(false)
            } else {
                toast.error(response.message, {
                    position: "top-center"
                })
            }

            setAdding(false);


        } catch (e) {
            setAdding(false);
        }

    }

    const handleDeleteLesson = (lessonID) => {

        setSelectedID(lessonID);
        setShowDeleteModal(true);

    }

    const handleUpdateLesson = async (lessonId) => {

        setIsUpdating(true);

        setSelectedID(lessonId);

        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/loadLesson?lessonId=${lessonId}&requestKey=${process.env.REACT_APP_SERVER_KEY}`);

            const response = await request.json();
        
            setTitle(response.data[0].title);
            setLessonDescription(response.data[0].description);
            setLessonThumbnailPhoto(response.data[0].thumbnailPhoto);
            setFileKey(Date.now());
            setVideoLink(response.data[0].videoLink);
            setVideoLength(response.data[0].length);
            setLessonStatus(response.data[0].status);
            setLessonQuiz({value:response.data[0].quizId,label:response.data[0].quizName})
            setShowAddLessonModal(true);

            setIsUpdating(false)

        } catch (e) {
            console.log(e)
            setIsUpdating(false);
            toast.error("An error occurred!", {
                position: "top-center"
            })
        }

    }

    return (
        <>
            <Header />
            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isUpdating &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }


                    <Row>
                        <Col lg="11">

                            <h1>{pageTitle}</h1>

                            <div className="shadow-lg" style={{ padding: 10, border: "1px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                {isLoading &&
                                    <div>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                            <Placeholder xs={6} /> <Placeholder xs={8} />
                                        </Placeholder>

                                        <Placeholder.Button variant="primary" xs={6} />
                                    </div>
                                }
                                {!isLoading &&
                                    <>
                                        <Row>
                                            <Col lg="8" className="mx-auto">
                                                <Form onSubmit={updateCourse} id="myForm">

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Category
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Select
                                                                value={category}
                                                                onChange={(selected) => { console.log(selected); setCategory(selected) }}
                                                                options={categories}
                                                                isSearchable={true}
                                                                placeholder="Search for a category..."
                                                                styles={customStyles}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Course Name
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Form.Control type="text" placeholder="Course Name" value={courseName} onChange={(e) => {
                                                                setCourseName(e.target.value)
                                                            }} />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Course Description
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <ReactQuill
                                                                value={courseDescription}
                                                                onChange={handleChange}
                                                                modules={modules}
                                                                formats={formats}
                                                                placeholder="Write something..."
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Thumbnail
                                                        </Form.Label>
                                                        <Col sm={9}>

                                                            <input type="file" onChange={handleImageChange} />

                                                            {thumbnailPhoto &&
                                                                <img src={thumbnailPhoto} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                                            }
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Intro video
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Form.Control type="text" placeholder="Intro Video" value={introVideo} onChange={(e) => {
                                                                setIntroVideo(e.target.value)
                                                            }} />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Quiz
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Select
                                                                value={quiz}
                                                                onChange={(selected) => {
                                                                    setQuiz(selected)
                                                                }}
                                                                options={quizzes}
                                                                isSearchable={true}
                                                                placeholder="Search for quiz..."
                                                                styles={customStyles}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Tutor
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Select
                                                                value={tutor}
                                                                onChange={(selected) => { setTutor(selected) }}
                                                                options={staffList}
                                                                isSearchable={true}
                                                                placeholder="Search for a Staff..."
                                                                styles={customStyles}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={3}>
                                                            Status
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Form.Control
                                                                as="select"
                                                                value={courseStatus}
                                                                onChange={(e) => setCourseStatus(e.target.value)}
                                                            >
                                                                <option value="">
                                                                    Change course status
                                                                </option>
                                                                <option value="Draft">
                                                                    Draft
                                                                </option>
                                                                <option value="Published">
                                                                    Published
                                                                </option>
                                                            </Form.Control>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3">
                                                        <Col sm={{ span: 10, offset: 2 }}>
                                                            <Button type="submit">Update</Button>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="8" className="mx-auto">
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    activeKey={key}
                                                    onSelect={(k) => setKey(k)}
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="Videos" title="Videos">
                                                        {/* START DISPLAY LESSONS */}

                                                        <Row style={{ marginBottom: "10px" }}>
                                                            <Col>
                                                                <Button className="btn-sm" variant="primary" onClick={() => {
                                                                    setActionType("addLesson");
                                                                    setShowAddLessonModal(true)
                                                                }}>
                                                                    Add New Lesson
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Title</th>
                                                                    <th>Length</th>
                                                                    <th>Status</th>
                                                                    <th>Update</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {lessons.map((lesson) => (
                                                                    <tr key={lesson.id}>
                                                                        <td>{lesson.title}</td>
                                                                        <td>{lesson.length}</td>
                                                                        <td>{lesson.status == "1" ? <>Active</> : <>Inactive</>}</td>
                                                                        <td>
                                                                            <Button variant="info" onClick={() => {
                                                                                setActionType("updateLesson");
                                                                                handleUpdateLesson(lesson.id)
                                                                            }} >
                                                                                Update
                                                                            </Button>
                                                                        </td>
                                                                        <td>
                                                                            <Button variant="danger" onClick={() => {
                                                                                handleDeleteLesson(lesson.id);
                                                                            }} >
                                                                                Delete
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>

                                                        {/* END DISPLAY LESSONS */}

                                                    </Tab>
                                                    <Tab eventKey="Enrollments" title="Enrollments">
                                                        Tab content for Enrollments
                                                    </Tab>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </>
                                }

                            </div>
                        </Col>
                    </Row>

                </Col>

            </Row>

            {/* Modal for Creating New Lesson */}
            <Modal show={showAddLessonModal} onHide={() => {
                clearForm();
                setShowAddLessonModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{actionType == "addLesson" ? <>Add New Lesson</> : <>Update lesson</>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Description
                            </Form.Label>
                            <Col sm={12}>
                                <ReactQuill
                                    value={lessonDescription}
                                    onChange={(e) => {
                                        setLessonDescription(e)
                                    }}
                                    modules={modules}
                                    formats={formats}
                                    placeholder="Write something..."
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                                Thumbnail
                            </Form.Label>
                            <Col sm={9}>

                                <input type="file" onChange={(e) => {
                                    handleLessonThumbnail(e);
                                }} key={fileKey} />

                                {lessonThumbnailPhoto &&
                                    <img src={lessonThumbnailPhoto} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Video Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Video Length</Form.Label>
                            <Form.Control
                                type="text"
                                value={videoLength}
                                onChange={(e) => setVideoLength(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Quiz</Form.Label>

                            <Col sm={12}>
                                <Select
                                    value={lessonQuiz}
                                    onChange={(selected) => {
                                        setLessonQuiz(selected)
                                    }}
                                    options={quizzes}
                                    isSearchable={true}
                                    placeholder="Search for quiz..."
                                    styles={customStyles}
                                />
                            </Col>

                        </Form.Group>

                        {actionType == "updateLesson" &&
                            <Form.Group className="mb-3">
                                <Form.Label>Lesson Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={lessonStatus}
                                    onChange={(e) => setLessonStatus(e.target.value)}
                                >
                                    <option value="">
                                        Change lesson status
                                    </option>
                                    <option value="1">
                                        Active
                                    </option>
                                    <option value="0">
                                        Inactive
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        }
                    </Form>

                    {adding &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" disabled={adding} onClick={handleSubmit}>
                        {adding == true ? <>Saving...</> : <>Save Changes</>}
                    </Button>
                    <Button variant="secondary" onClick={() => {
                        clearForm();
                        setShowAddLessonModal(false)
                    }}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            {/* Modal for Updating Lesson */}
            <Modal show={showDeleteModal} onHide={() => {
                setSelectedID("");
                setShowDeleteModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Lesson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        This is a permanent and irreversible action!
                    </p>
                    <p>
                        Click yes if you are sure!!!
                    </p>

                    {adding &&
                        <center>
                            <div>
                                <span className="loader"></span>
                            </div>
                        </center>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={deleteLesson}>
                        Yes
                    </Button>
                    <Button variant="danger" onClick={() => {
                        setSelectedID("");
                        setShowDeleteModal(false);
                    }}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default EditCourse;